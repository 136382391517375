<template>
  <div
    class="tab-pane fade show active"
    id="pills-1"
    role="tabpanel"
    aria-labelledby="pills-1-tab"
  >
    <div class="tourn-banner my-4">
      <div class="card flex-md-row justify-content-md-between">
        <div class="col-md-4 card-img-wrapper order-md-2">
          <img
            :src="
              tourbinary &&
              tourbinary[0] &&
              tourbinary[0].filepath
                | get_img(
                  'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                )
            "
          />
        </div>

        <div class="col-md-8 d-flex flex-column order-md-1">
          <div class="card-body">
            <span class="tourn-type badge rounded-pill"
              >{{ tournament_type(tournamentinfo.type) }} /
              {{ tournamentinfo.tour_rank_type | tour_rank_type }}</span
            >
            <h3 class="card-title">
              {{ tournamentinfo.title }}
            </h3>
          </div>

          <div class="card-footer">
            <div class="row gx-2 gy-1">
              <div 
                class="col-auto text-date"
                v-if="
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_private
                "                
              >
                <!-- 사용자 시간 -->
                <dfn
                  class="time-zone-mark badge rounded-pill bg-orange"
                  title="Connect time"
                  >CT</dfn
                >
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_private
                    | dateformat
                }}
                ~
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_enddate.timezone_private | dateformat
                }}
              </div>
              <div class="col-auto text-date">
                <!-- 서비스 시간 -->
                <dfn
                  class="time-zone-mark badge rounded-pill bg-green"
                  title="Standard time"
                  >ST</dfn
                >
                  {{
                    tournamentinfo.timezone_startdate &&
                    tournamentinfo.timezone_startdate.timezone_common | dateformat
                  }}
                  ~
                  {{
                    tournamentinfo.timezone_startdate &&
                    tournamentinfo.timezone_enddate.timezone_common | dateformat
                  }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="border-top-dgray mt-4">
      <div class="row g-0 border-bottom">
        <div class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center">
          <label class="d-table-cell">{{ $t("title.common.information") }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            {{ tournamentinfo.remark }}
          </p>
        </div>
      </div>      
      <div class="row g-0 border-bottom">
        <div class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center">
          <label class="d-table-cell">{{ $t("title.common.course") }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            <span v-for="(course, index) in tournamentcourse" :key="index"
              >{{ index === 0 ? "" : ", " }}{{ course.coursename }}</span
            >
          </p>
        </div>
      </div>

      <div class="row g-0 border-bottom">
        <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
          <label class="d-table-cell align-self-center">{{
            $t("text.common.shortcourse")
          }}</label>
        </div>
        <div v-if="subcourselistcnt" class="col-8 col-lg-10">
          <div
            v-for="(anc, index1) in subcourselist"
            :key="'k_' + index1"
            class="subcourse"
          >
            <h6>{{ anc.subcoursename }}</h6>
            <small class="d-block text-dgray"
              >{{ $t("text.common.shortcourse") }} {{ anc.subcourseseq }}</small
            >
          </div>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
          <label class="d-table-cell align-self-center">{{
            $t("title.common.point_allocation")
          }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            <span>
              {{ tournamentinfo.tour_class | tour_class }}
              {{ $t("title.common.standard_point") }}
              {{
                tournamentinfo.oom_point == 1
                  ? "+ " + $t("title.common.oom_point")
                  : ""
              }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="border-top-dgray mt-5">
      <!-- <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">매장</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <p class="d-table-cell">
            <span v-for="(shop, index2) in tournamentshop" :key="index2"
              >{{ index2 === 0 ? "" : " / " }}{{ shop.shopname }}</span
            >
          </p>
        </div>
      </div> -->
      <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">{{ $t("title.common.prizes") }}</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <div class="row g-0 border-bottom">
            <div class="col-lg-12">
              <p class="d-table-cell">
                {{ tournamentprizeetc.etcprize || "Unregistered" }}
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom-0">
            <div class="col-lg-12">
              <p class="d-table-cell">
                <span
                  class="d-block"
                  v-for="(rank, index3) in tournamentprizerank"
                  :key="index3"
                  ><strong class="fw-500 text-black"
                    >{{ $t("text.common.top") }}{{ rank.rankno }}</strong
                  >
                  :
                  <span class="text-prize">{{ rank.rankprize | comma }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-2 bg-gray row g-0 align-items-center border-bottom">
          <label class="d-table-cell">{{
            $t("title.common.tournament_conditions")
          }}</label>
        </div>
        <div class="col-lg-10">
          <div class="row g-0 border-bottom-0">
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.game_mode")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.mode }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.level")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournament_level(tournamentinfo.level) }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.entry_limit")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center text-red">
                    {{ tournamentinfo.entrylimit | transToValue("∞") }}
                  </p>
                </div>
              </div>
              <div
                class="row g-0 border-bottom d-flex flex-row align-items-stretch h-100"
              >
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.mulligan")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.mulligan }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.play_format")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">{{ tournamentinfo.hole }}H</p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.wind")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">{{ wind }}</p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.tournament.rank_type")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.tour_rank_type | tour_rank_type }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.teebox")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ $t("title.common.male") }}: {{ teemale }}<br />
                    {{ $t("title.common.female") }}: {{ teefemale }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row g-0 border-bottom-0">
            <div class="col-lg-3 bg-gray row align-items-center">
              <label class="d-table-cell">Handicap(No. of Play)</label>
            </div>
            <div class="col-lg-9">
              <p class="d-table-cell">{{ tournamentinfo.playhandicap }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentCondition",
  data() {
    return {
      tournamentno: this.$route.params.id,
      tournamentinfo: [
        {
          title: "",
          remark: "",
          startdate: "",
          enddate: "",
          mode: "",
          hole: "",
          level: "",
          wind: "",
          mulligan: "",
          entrylimit: "",
          teeboxman: "",
          teeboxwoman: "",
          playhandicap: "",
        },
      ],
      tournamentcourse: [],
      tournamentshop: [],
      tournamentprizeetc: [
        {
          etcprize: "",
        },
      ],
      tournamentprizerank: [],
      tournamentinfo: [{}],
      wind: "",
      teemale: "",
      teefemale: "",
      subcourselist: [],
      tourbinary: [],
      subcourselistcnt: 0,
    };
  },
  methods: {
    get_tournamentcondition() {
      ax.get_tournamentcondition(this.tournamentno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.tournamentinfo = data.tournamentinfo;
          this.tournamentcourse = data.tournamentcourse;
          this.tournamentshop = data.tournamentshop;
          this.tournamentprizeetc = data.tournamentprizeetc;
          this.tournamentprizerank = data.tournamentprizerank;

          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
          this.tourbinary = data.tourbinary;
          this.change_wind(this.tournamentinfo.wind);
          this.change_male(this.tournamentinfo.teeboxman);
          this.change_female(this.tournamentinfo.teeboxwoman);
        } else {
          alert(data);
        }
      });
    },
    // change_wind(wind) {
    //   if (wind === "0") {
    //     this.wind = this.$i18n.t("text.common.calm");
    //   } else if (wind === "1") {
    //     this.wind = this.$i18n.t("text.common.light");
    //   } else if (wind === "2") {
    //     this.wind = this.$i18n.t("text.common.moderate");
    //   } else if (wind === "3") {
    //     this.wind = this.$i18n.t("text.common.strong");
    //   } else if (wind === "4") {
    //     this.wind = this.$i18n.t("text.common.random");
    //   } else {
    //     this.wind = wind;
    //   }
    // },
    // change_male(teeboxman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teemale = teeboxman;
    //   }
    // },
    // change_female(teeboxwoman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teefemale = teeboxwoman;
    //   }
    // },
  },
  created() {
    this.get_tournamentcondition();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  mixins: [myMixin],
};
</script>